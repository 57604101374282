import useAxiosHook, { Options } from 'axios-hooks'
import Axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import { configure } from 'axios-hooks';

const API = Axios.create({
    baseURL: process.env.REACT_APP_BOARDGUARD_API_URL,
    headers: new AxiosHeaders({
        Authorization: `Bearer ${process.env.REACT_APP_BOARDGUARD_API_TOKEN}`
    })
})

configure({ axios: API, defaultOptions: {
  autoCancel: false
}})

export const useBoardguardAPI = (params: string | AxiosRequestConfig, options?: Options) => { 
    const config: AxiosRequestConfig = useMemo(() => {
        let newConfig: AxiosRequestConfig = {}

        if (typeof params === "string") {
            newConfig.url = params
        } else {
            newConfig = {
                ...params,
            }
        }

        return {
            ...newConfig
        }
    }, [params])

    return useAxiosHook(config, options)
}