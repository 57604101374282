import { Grid, useTheme } from "@mui/material";
import { AIRLINE_CARD_TYPE, AirlineCard } from "./components";

function App() {
  const theme = useTheme()

  return (
    <div style={{
      minHeight: '100vh',
      width: '100vw',
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Grid container spacing={6} padding={2} width={'60%'}>
        <Grid item md={6}>
          <AirlineCard airline={AIRLINE_CARD_TYPE.GOL} />
        </Grid>
        <Grid item md={6}>
          <AirlineCard airline={AIRLINE_CARD_TYPE.TAP} />
        </Grid>
        <Grid item md={6}>
          <AirlineCard airline={AIRLINE_CARD_TYPE.LATAM} />
        </Grid>
        <Grid item md={6}>
          <AirlineCard airline={AIRLINE_CARD_TYPE.AZUL} />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
