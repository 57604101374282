import { Grid, TextField, Paper, Button, AlertTitle, Alert } from "@mui/material"
import { useFormik } from "formik"
import { useBoardguardAPI } from "../hooks"

export enum AIRLINE_CARD_TYPE {
  GOL = 'GOL',
  LATAM = 'LATAM',
  TAP = 'TAP',
  AZUL = 'AZUL'
}

const logos = {
  [AIRLINE_CARD_TYPE.GOL]: 'gol.png',
  [AIRLINE_CARD_TYPE.TAP]: 'tap.png',
  [AIRLINE_CARD_TYPE.LATAM]: 'latam.png',
  [AIRLINE_CARD_TYPE.AZUL]: 'azul.png',
}

type AirlineCardProps = { 
  airline: AIRLINE_CARD_TYPE
} 

export const AirlineCard = ({ airline }: AirlineCardProps) => {
    const [apiCheckerResult, apiChecker] = useBoardguardAPI(`/check/${airline}`, { manual: true })

    const formik = useFormik({
      initialValues: {
        origin_airport: '',
        pax_name: '',
        reservation_code: ''
      },
      onSubmit: (values) => {
        apiChecker({
          params: values
        })
      }
    })

    return (
      <Paper style={{
        height: 500
      }}>
        <form onSubmit={formik.handleSubmit} style={{height: '100%'}}>
          <Grid container padding={2} spacing={1} style={{height: '100%'}}>
            <Grid item xs={12}>
              <Grid container height={"100%"}>
                <Grid item xs={12} textAlign={"center"} mb={2}>
                  <img src={logos[airline]} height={50}/>
                </Grid>
                <Grid item xs={12}>
                  {apiCheckerResult?.data?.data ? (
                    <Alert color={apiCheckerResult?.data?.data.status === 'VALID' ? 'success' : 'warning'}>
                      <AlertTitle>Resultado:</AlertTitle>
                      <strong>{apiCheckerResult?.data?.data.status}</strong><br/><br/>
                      <span style={{fontStyle: 'italic'}}>{JSON.stringify(apiCheckerResult?.data)}</span>
                    </Alert>
                  ) : (
                    <Alert color='info'>
                      <AlertTitle>Verifique o bilhete:</AlertTitle>
                      <span>Preencha os campos abaixo e verifique o bilhete.</span>
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item display={'flex'} alignItems={'end'}>
              <Grid container spacing={1}>
                {[AIRLINE_CARD_TYPE.AZUL, AIRLINE_CARD_TYPE.GOL].includes(airline) && (<Grid item xs={12} textAlign={"center"}>
                  <TextField 
                    fullWidth
                    label="Origem"
                    name="origin_airport"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>)}
                {airline !== AIRLINE_CARD_TYPE.AZUL && (<Grid item xs={12}>
                  <TextField 
                    fullWidth
                    label="Nome do Passageiro"
                    name="pax_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>)}
                <Grid item xs={12} textAlign={"center"}>
                  <TextField 
                    fullWidth
                    label="Localizador"
                    name="reservation_code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                  <Button variant="contained" type="submit" disabled={apiCheckerResult.loading}>
                    Consultar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    )
}